// Import Bootstrap and its default variables
$body-bg: #000;
$body-color: #111;
$grid-columns: 20 !default;
$grid-gutter-width: 10px !default;

@import "~bootstrap/scss/bootstrap.scss";

@import "breakpoints";
@import "colors";
@import "template";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');