@import "../../styles/colors.scss";

.order-table {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    th {
        font-weight: 600;
    }
    tbody:not(:last-of-type)::after {
        content: "";
        display: block;
        height: 15px;
    }
}
