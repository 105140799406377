.simple-input {
    input {
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        text-decoration: underline;
        text-underline-offset: 2px;
        font-family: 'LATO';
        font-size: 18px;
        width: -webkit-fill-available;
        &.empty {
            border-bottom: 1px solid black;
            width: 60px;
            height: 20px;
        }
    }
}