@import "../../styles/colors.scss";

.product-row {
    td {
        border: 1px solid $border-gray;
    }
    .product-name {
        display: flex;
        background-color: $white;
        text-align: start;
        font-weight: 700;
        text-decoration: underline;
        padding: 11px 20px;
        a {
            color: black;
            p {
                width: 200px;
            }
        }
        img {
            margin-right: 15px;
        }
    }
    .left-to-deliver {
        font-size: 18px;
        font-weight: 600;
    }
    .input-cell {
        height: 0;
        padding: 0;
        border: 1px solid rgba(68, 66, 67, 0.2);
        position: relative;
        .unit {
            position: absolute;
            right: 21px;
            top: 50%;
            transform: translate(0px, -50%);
            font-size: 18px;
            font-weight: 600;
            color: $blue;
        }
    }
}
