@import "./colors.scss";

body {
    font-family: 'Open Sans', sans-serif;
    height: unset;
    background: $lightgray;
}

p {
    margin: 0;
}

.blue {
    color: $blue;
}

.error {
    color: $red;
    font-weight: normal!important;
}

.green {
    color: $green;
}

.weight-600 {
    font-weight: 600!important;
}

.weight-700 {
    font-weight: 700!important;
}

.no-style-btn {
    height: fit-content;
    border: none;
    background-color: transparent;
}