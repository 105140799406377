@import '../../styles/colors.scss';

.bdn-modal {
    padding: 30px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    p {
        text-align: center;
        margin-bottom: 28px;
    }
    .modal-title {
        font-weight: bold;
        font-size: 18px;
    }
    div {
        display: flex;
        justify-content: space-between;
    }
}