@import "../../styles/colors.scss";

.styled-input{
    position: relative;
    input {
        font-family: 'Lato', sans-serif;
        padding: 8px 6px;
        border: 2px solid #444243;
        border-radius: 4px;
        font-size: 18px;
        width: 100%;
        &::placeholder {
            color: rgba(0, 0, 0, 0.5)
        }
        &.success, &:focus, &:active, &:focus-visible {
            border-color: $blue;
        }
        &.success {
            color: $blue;
        }
        &:disabled {
            background: white;
        }
        &.error {
            border-color: $red;
            color: $red;
        }
    }
    img {
        width: 20px;
        margin-left: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}