@import "../../styles/colors.scss";

.primary-outline {
    background: transparent;
    border: 2px solid $blue;
    border-radius: 4px;
    color: $blue;
    font-weight: bold;
    padding: 8px 58px;
    &:disabled {
        border: 2px solid $gray;
        color: $gray;
    }
}

.secondary-outline {
    background: $white;
    border: 2px solid $darkgray;
    border-radius: 4px;
    color: $darkgray;
    font-weight: bold;
    padding: 7px 0;
}

.icon-btn {
    position: relative;
    background: transparent;
    border: 2px solid $blue;
    border-radius: 4px;
    color: $blue;
    font-weight: bold;
    padding: 8px 40px 8px 60px;
    img {
        position: absolute;
        height: 20px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.primary-btn {
    position: relative;
    background: $blue;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    padding: 10px 60px;
    color: $white;
    &:active {
        background: #005dae;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
        background: rgba(68, 66, 67, 0.6);
    }
}

.secondary-btn {
    position: relative;
    background: $darkgray;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    padding: 10px 60px;
    color: $white;
    &:active {
        background: #252425;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
        background: rgba(68, 66, 67, 0.6);
    }
}

.btn-text {
    margin: 0!important;
}