.input-modal {
    position: fixed;
    z-index: 3;
    background: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    .input-inside {
        width: 75%;
        height: fit-content;
        input {
            height: fit-content;
            font-family: 'Lato', sans-serif;
            padding: 10px 10px;
            border: 3px solid #0583F2        ;
            border-radius: 4px;
            font-size: 22px;
            width: 100%;
            &::placeholder {
                color: rgba(0, 0, 0, 0.5)
            }
            &:focus-visible {
                outline: none;
            }
        }
    }
}