@import '../../styles/colors.scss';

.cell-input {
    height: 100%;
    input {
        width: 100%;
        height: 100%;
        background-color: $lightblue;
        color: $blue;
        font-size: 18px;
        font-weight: 600;
        border: none;
        text-align: center;
        padding: 10px;
        box-shadow: inset 0px 4px 4px rgba(5, 131, 242, 0.25);
    }
}