.ring {
    width: 80px;
    height: 80px;
  }
  .ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: black transparent black transparent;
    animation: ring 1.2s linear infinite;
  }
  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .ring.small {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    position: relative;
    top: -6px;
    left: -5px;
  }
  .ring.small:after {
    width: 28px;
    height: 28px;
  }


.cross{
  position: absolute;
  right: 20px;
  top: 10px;
  height: 50px;
}

.global{
    width: 50%;
    height: 33vh;
    display: flex;
    align-items: center;
}