.pending {
    text-align: center;
    h1 {
        margin: 40px 0;
    }

    .code {
        margin-bottom: 30px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}