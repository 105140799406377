.products {
    position: relative;
    p {
        font-weight: 600;
        text-decoration: underline;
    }
    .product-dropdown {
        margin-top: 5px;
        position: absolute;
        top: 0;
        left: -145px;
        background-color: white;
        width: 150px;
        padding: 5px;
        border-radius: 4px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        p {
            text-decoration: unset;
            padding: 5px 0;
            width: 100%!important;
            &:not(:last-child) {
                border-bottom: 1px solid rgba(5,131,242, 0.2);
            }
        }
    }
}