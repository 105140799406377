@import "../../styles/colors.scss";

.orders {
    display: flex;
    justify-content: space-around;
    padding: 0 5%;
    .no-orders {
        margin-top: 20%;
        img {
            width: 60px;
        }
        p {
            color: $darkgray;
            font-weight: 600;
            font-size: 30px;
            align-items: center;
            margin-left: 25px;
        }
    }
    .orders-item-content {
        width: 100%;
    }
}

.btns-group {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    button {
        margin-bottom: 20px;
    }
}