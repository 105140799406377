.order-item-status {
    width: 100%;
    height: 70px;
    border-radius: 5px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    *:not(:last-child) {
        width: 30%;
    }
    p {
        font-weight: 400;
    }
    .order-item {
        height: 100%;
        width: 99%;
        background-color: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3% 0 5%;
        .customer {
            text-transform: uppercase;
            font-weight: 700;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .regular {
            font-weight: 600;
        }
        .left-to-deliver {
            color: #0583f2;
            margin-left: 5px;
        }
    }
}
