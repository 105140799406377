.label {
    width: fit-content;
    padding: 5px 10px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    background-color: white;
    display: flex;
    .status {
        font-weight: 700;
        text-transform: uppercase;
    }
    img {
        margin-right: 10px;
    }
    p {
        white-space: nowrap
    }
}