@import "../../styles/colors.scss";

.header {
    height: 90px;
    .header-fixed {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        width: 100%;
        height: 90px;
        box-shadow: 0px 2px 23px rgba(0, 0, 0, 0.1);
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        padding: 0 20px;
        button {
            height: 80%;
            border: none;
            background-color: transparent;
            img {
                height: 100%;
            }
        }
        h1 {
            font-size: 30px;
            font-weight: 700;
        }
        .historydiv {
            margin-right: 20px;
            width: 80%;
            display: flex;
            flex-direction: row-reverse;
        }
        .time {
            text-align: right;
            font-weight: bold;
            font-size: 15px;
        }
    }
}