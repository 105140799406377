@import "../../styles/colors.scss";

.history {
    display: flex;
    justify-content: space-around;
    padding: 2% 5%;
    .history-content {
        width: 90%;
        .history-filter {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            p {
                font-weight: 600;
                margin-right: 20px;
            }
            select {
                padding: 10px;
                color: $blue;
                border: none;
                background-color: $white;
                font-weight: 600;
                border-radius: 5px;
                margin-right: 20px;
            }
            input {
                border-radius: 5px;
                border: none;
                padding: 10px;
                box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                &::placeholder {
                    color: $bg-gray;
                }
            }
        }
        .history-item-content {
            width: 100%;
        }
    }
}