@import "../../styles/colors.scss";

.print {
    padding: 0 10px;
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    z-index: 10;
    background-color: $white;
    .center {
        display: flex;
        justify-content: center;
    }
    .bdn-logo {
        width: 150px;
        filter: grayscale(100%);
    }
    .sign {
        max-width: 300px;
        max-height: 300px;
    }
    .ref {
        white-space: nowrap;
        margin-right: 10px;
    }
    .separator {
        width: 100%;
        border: 1px dashed black;
        margin: 10px 0;
    }
    li:not(:last-of-type) {
        border-bottom: 1px dashed black;
    }
    .space-blanck {
        height: 100px;
    }
    .print-header {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}
