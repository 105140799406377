@import "../../styles/colors.scss";

.table-tabs {
    display: flex;
    padding: 0 2%;
    justify-content: space-between;
    .tab {
        background-color: $darkgray;
        color: white;
        border: none;
        padding: 8px 10px;
        font-weight: 700;
        font-size: 17px;
        border-radius: 3px 3px 0px 0px;
    }
    .selected {
        background-color: white;
        color: black;
    }
}