@import "../../styles/colors.scss";

.product-item {
    position: relative;
    width: calc(100% / 6) !important;
    padding-bottom: calc(var(--bs-gutter-x));
    padding-top: calc(var(--bs-gutter-x));
    .no-style-btn {
        width: 100%;
    }
    .product-display {
        background-color: lightblue;
        height: 120px;
        border-radius: 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #444243;
        padding: 10px;
        font-weight: 800;

        &-checked{
            color: white;
        }
        p {
            font-size: 15px;
            position: relative;
            z-index: 1;
        }
    }
    .checked {
        border: 5px solid $blue !important;
        width: 87%;
        height: 120px;
        position: absolute;
        background-color: #0583f29c;
        border-radius: 5px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        img {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
.fav-btn {
    position: absolute;
    right: -10px;
    top: 0;
    background-color: transparent;
    border: none;
}
