@import "../../styles/colors.scss";

.footer {
    margin-top: 70px;
    height: 72%;
    .footer-fixed {
        z-index: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        justify-content: space-between;
        display: flex;
        background: $white;
        box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.1);
        padding: 8px 10px 20px;

        @media (max-height: 350px) {
            display: none;
          }
    }
}