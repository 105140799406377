.new-order{
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    .parts {
        width: 49%;
    }
    .input-container {
        margin-bottom: 15px;
        p {
            font-weight: 700;
            font-size: 18px;
            margin-right: 20px;
        }
    }
}