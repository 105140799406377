$body-bg: #f2f2f2;
$red: #F24405;
$blue: #0583F2;
$lightblue: #E6F3FE;
$green: #C4D926;
$darkgray: #444243;
$gray: #949393;
$bg-gray: #B8B8B8;
$lightgray: #F6F6F6;
$border-gray: #d9d9d9;
$white: #FFFFFF;


:export {
    red: $red;
    blue: $blue;
    lightblue: $lightblue;
    green: $green;
    darkgray: $darkgray;
    gray: $gray;
    bg-gray: $bg-gray;
    lightgray: $lightgray;
    border-gray: $border-gray;
    white: $white;
}
