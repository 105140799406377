@import '../../styles/colors.scss';

.tabletop-container {
    height: 60px;
    .tabletop {
        z-index: 1;
        display: flex;
        justify-content: space-around;
        padding: 20px 8% 15px;
        position: fixed;
        background-color: $white;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        font-weight: 700;
    }
}