.auto-complete {
    position: absolute;
    background-color: white;
    width: 74.5%;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 0;
    border-radius: 0 0 4px 4px;
    margin-left: 2px;
    button {
        border: none;
        display: block;
        background-color: transparent;
        font-family: Lato;
        padding: 5px 10px;
        width: 100%;
        text-align: left;
    }
}

.client--inactive {
    color: red;
}