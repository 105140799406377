@import '../../styles/colors.scss';

.summary {
    padding: 15px 25px;
    display: flex;
    .order-product-table {
        width: 55%;
    }
    .add-link {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        a {
            color: $blue;
            text-decoration: none;
            font-weight: 600;
        }
    }
    .modify {
        font-weight: 600;
        width: 40%;
        margin-top: 20px;
    }
    .infos-half {
        width: 45%;
        padding-left: 30px;
        .order-infos {
            padding: 20px;
            background-color: $white;
            border: 1px solid rgba(0, 0, 0, 0.2);
            .infos-line {
                display: flex;
                align-items: center;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                .customer {
                    font-family: 'LATO';
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: unset;
                    color: $darkgray;
                }
                p {
                    font-weight: 700;
                    margin-right: 20px;
                }
            }
        }
    }
}