@import "../../styles/colors.scss";

.end-review {
    padding: 15px 25px;
    display: flex;
    .order-product-table {
        width: 55%;
        .review-table {
            background-color: $white;
            display: flex;
            padding: 10px;
            position: relative;
            min-height: 200px;
            padding-left: 50px;
            .product-title {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 28px;
                transform: rotate(-90deg);
                left: -60px;
                top: 40%;
                position: absolute;
            }
            .reviews-list {
                width: 100%;
            }
        }
        .sign-title {
            font-weight: 700;
            margin: 8px 0;
        }
        .signature {
            background-color: $white;
            height: 160px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            img {
                height: 90%;
                max-width: 100%;
            }
        }
    }
    .infos-half {
        width: 45%;
        padding-right: 30px;
        .order-infos {
            padding: 20px;
            background-color: $white;
            border: 1px solid rgba(0, 0, 0, 0.2);
            .infos-line {
                display: flex;
                align-items: center;
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
                p {
                    font-weight: 700;
                    margin-right: 20px;
                }
                .fake-input {
                    font-family: "LATO";
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: unset;
                    color: $darkgray;
                }
            }
        }
    }
}

.footer-grp {
    display: flex;
    justify-content: space-between;
    width: 66%;
}
