.home {
  margin: 6% 20%;
}
.sync {
  font-weight: 700;
  align-items: center;
  justify-content: flex-end;
  margin: 20px;
  p {
    margin-right: 10px;
    align-content: flex-end;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &_reload {
    margin: 20px;
    width: 50px;
    background-color: transparent;
    border: none;
  }

  &_sync {
    margin: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &_text {
      margin-right: 10px;
      align-content: flex-end;
      font-weight: 700;
    }
  }
}
