@import '../../styles/colors.scss';

.signature {
    height: calc(100vh - 160px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .sign-canvas{
        background-color: $white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .error {
        position: absolute;
        bottom: 50px;
        right: 60px;
    }
}