@import '../../styles/colors.scss';

.review-item {
    display: flex;
    justify-content: space-between;
    border: 1px solid $border-gray;
    border-radius: 5px;
    font-weight: 700;
    margin-bottom: 6px;
    div {
        padding: 10px;
    }
    .quantity {
        border: 1px solid $border-gray;
        border-radius: 0 2px 2px 0;
        width: 25%;
        text-align: center;
        background-color: $lightgray;
    }
    .waste {
        background-color: $lightblue;
    }
}